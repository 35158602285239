<template>
    <div class="agendaroom-vote">
        <div class="date">议事表决时间：{{ time }}</div>
        <div id="agendaroomStatisticsVoteChart" class="chart" :style="{ height: chartHeight + 'px' }"></div>

        <Table size="small" :columns="columns" :data="dataList" height="400px" style="margin:10px 20px;"></Table>

        <div class="situation-box">
            <div class="head-box">
                <div class="title">参与表决情况:</div>
                <div class="remind" @click="onRemind" v-if="users.noVote && users.noVote.length > 0">提醒未表决人</div>
            </div>
            <div class="menu-bar-box">
                <div class="menu-box">
                    <div class="slider" :style="{ width: 100 / 2 + '%', left: activity * (100 / 2) + '%' }"></div>
                    <div class="item-box" :style="{ width: 100 / 2 + '%' }" :class="{ activity: 0 === activity }" @click="activity = 0">已表决（{{ users.vote ? users.vote.length : 0 }}）</div>
                    <div class="item-box" :style="{ width: 100 / 2 + '%' }" :class="{ activity: 1 === activity }" @click="activity = 1">未表决（{{ users.noVote ? users.noVote.length : 0 }}）</div>
                </div>
            </div>
            <div class="users-box">
                <div class="item-box" v-for="item in users[activity === 0 ? 'vote' : 'noVote']" :key="item">
                    <div class="avatar-box">
                        <img class="img" v-if="item.avatarUrl" :src="item.avatarUrl" mode="aspectFill" />
                        <Icon type="md-person" v-else />
                    </div>
                    <div class="username">{{ item.realName }}</div>
                </div>
                <div class="empty" v-if="!users[activity === 0 ? 'vote' : 'noVote'] || users[activity === 0 ? 'vote' : 'noVote'].length <= 0">暂无数据</div>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from "echarts"
import Request from "../../jointly/utils/request"
import ElemTable from "../../communitymobilization/components/ElemTable.vue"

export default {
    components: {
        ElemTable,
    },

    data() {
        return {
            chartHeight: 200,
            activity: 0,
            users: {
                noVote: null,
                vode: null,
            },

            columns: [
                {
                    title: "姓名",
                    minWidth: 80,
                    key: "userName",
                },
                {
                    title: "手机号",
                    minWidth: 120,
                    key: "mobile",
                },
                {
                    title: "投票结果",
                    minWidth: 150,
                    key: "voteItemTitle",
                },
                {
                    title: "投票时间",
                    key: "time",
                    minWidth: 140,
                    render: (h, params) => {
                        return h("span", this.$core.formatDate(new Date(params.row.voteTime), "yyyy-MM-dd hh:mm:ss"))
                    },
                },
            ],

            dataList: [],
        }
    },

    props: {
        issueId: String,
        flowId: String,
        title: String,
        time: String,
        voteId: String,
    },

    created() {
        this.getVoteDetail()
        this.getUserVoteData()
        // this.getS()

        Request.get("/gateway/syinfopublish/api/pc/componentVote/getVoteRecordPage", {
            voteId: this.voteId,
            page: 1,
            pageSize: 99999,
        }).then(res => {
            this.dataList = res
        })
    },

    methods: {
        display(param) {
            this.issueId = param.id
            this.flowId = param.flowId

            if (!param.t || !param.d) {
                this.getFlowInfo()
            } else {
                this.title = decodeURI(param.t)
                this.time = decodeURI(param.d)
            }

            this.getVoteDetail()
            this.getUserVoteData()
        },

        getVoteDetail() {
            Request.get("/gateway/syinfopublish/api/app/chamber/statVoteRecordRank", {
                issueId: this.issueId,
                flowId: this.flowId,
            }).then(res => {
                const names = []
                const value = []

                for (let i = 0; i < res.length; i++) {
                    let v = res[i]

                    names.push(v.voteItemName)
                    value.push(v.total)
                }

                this.chartHeight = names.length * 100

                this.$nextTick(() => {
                    // 绘制图表
                    this.drawChart(names, value)
                })
            })
        },

        async getUserVoteData() {
            for (let i = 0; i <= 1; i++) {
                await Request.get("/gateway/syinfopublish/api/app/chamber/getChamberIssueVoteUser", {
                    flowId: this.flowId,
                    personVote: i,
                }).then(res => {
                    this.$set(this.users, i === 0 ? "noVote" : "vote", res)
                })
            }
        },

        getFlowInfo() {
            return Request.get("/gateway/syinfopublish/api/app/chamber/getIssueFlowInfo", {
                issueId: this.issueId,
            }).then(res => {
                this.title = res.title

                for (let i = 0, ls = res.flowVoList; i < ls.length; i++) {
                    const v = ls[i]

                    if (v.flowCode === "2002") {
                        let json = JSON.parse(v.flowJson)
                        this.time = `${json.startTime} - ${json.endTime}`
                        break
                    }
                }
            })
        },

        drawChart(names, data) {
            let el = document.getElementById("agendaroomStatisticsVoteChart")
            // 销毁图表
            echarts.dispose(el)
            // 初始化图表
            echarts.init(el).setOption({
                title: {
                    left: "center",
                    text: "已表决结果统计",
                },
                xAxis: {
                    max: "dataMax",
                },
                yAxis: {
                    type: "category",
                    data: names,
                    inverse: true,
                    animationDuration: 300,
                    animationDurationUpdate: 300,
                },
                series: [
                    {
                        realtimeSort: true,
                        type: "bar",
                        data: data,
                        label: {
                            show: true,
                            position: "right",
                            valueAnimation: true,
                        },
                    },
                ],
                legend: {
                    show: true,
                },
            })
        },

        onRemind() {
            Request.post(
                "/gateway/syinfopublish/api/app/chamber/notifyNonParticipants",
                {
                    issueId: this.issueId,
                    flowId: this.flowId,
                },
                {
                    success: "提醒成功",
                }
            )
        },
    },
}
</script>
<style lang="less">
.agendaroom-vote {
    .title-box {
        margin: 0 20px;
        padding-top: 15px;
        text-align: center;
        font-size: 18px;
        color: #000;
    }

    .date {
        margin: 10px 20px;
        font-size: 13px;
        color: #666;
        text-align: center;
    }

    .chart {
        margin: 10px 20px 0 20px;
        height: 250px;
    }

    .situation-box {
        margin: 0 20px;

        .head-box {
            display: flex;
            justify-content: space-between;

            .title {
                font-size: 16px;
                color: #666;
            }

            .remind {
                cursor: pointer;
                padding: 6px 15px;
                font-size: 14px;
                border-radius: 5px;
                line-height: 1;
                border: 1px solid #e3e3e3;
                color: #333;
            }
        }

        .menu-bar-box {
            width: 100%;
            height: 45px;
            margin: 10px 0 20px 0;

            .menu-box {
                position: relative;
                width: 100%;
                height: 100%;
                border: 1px solid #f3f3f3;
                background: #fff;
                border-radius: 5px;
                display: flex;

                .slider {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    width: 50%;
                    transition: all 0.3s ease;

                    &::after {
                        content: "";
                        background: #2faaf7;
                        position: absolute;
                        top: 5px;
                        left: 5px;
                        bottom: 5px;
                        right: 5px;
                        border-radius: 3px;
                    }
                }

                > .item-box {
                    cursor: pointer;
                    position: relative;
                    height: 100%;
                    z-index: 10;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.3s ease;
                }

                > .activity {
                    color: #fff;
                }
            }
        }

        .users-box {
            .item-box {
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                padding-bottom: 15px;
                border-bottom: 1px solid #f3f3f3;

                &:last-child {
                    border-bottom: initial;
                }

                .avatar-box {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #f3f3f3;

                    .img {
                        width: 100%;
                        height: 100%;
                    }

                    .default {
                        width: 80%;
                        height: 80%;
                    }
                }

                .username {
                    font-size: 16px;
                    margin-left: 10px;
                }
            }

            .empty {
                position: relative;
                width: 100%;
                text-align: center;
                margin: 75px 0;
                font-size: 13px;
                color: #888;
            }
        }
    }
}
</style>
