<template>
    <div>
        <CompContent :title="(detail ? detail.title + ' - ' : '') + '流程'">
            <div class="agendaroom-evolve">
                <div class="evolve-box">
                    <div class="scroll-box">
                        <TimelineEdit :data="timelineData" :current="current" @on-select="onSelectFlow"></TimelineEdit>
                        <div class="prompt">提示：根据议事内容的需要，可以向下逐步开启必要的环节，线上不用开展的环节可跳过。</div>
                    </div>
                    <button class="success-btn" @click="onSubmitComplete" v-if="detail && detail.flowCode == '2004'">完成并归档</button>

                    <div class="handle-page" v-show="isDisplayHandlePage">
                        <div class="handle-box" v-if="currentStepData">
                            <div class="head-box">
                                <div class="title-box">
                                    <div class="name">{{ currentStepData.title }}</div>
                                </div>
                                <div class="cancel" @click="isDisplayHandlePage = false">取消</div>
                            </div>

                            <div class="radio-box" v-if="currentStepData.flowCode == 2003 || currentStepData.flowCode == 2004">
                                <RadioGroup class="radio-group" v-model="handleData.publicity_type">
                                    <Radio v-for="(item, idx) in currentStepData.flowCode == 2003 ? ['主动公示', '免于公示'] : ['主动公开评议', '免于公开评议']" :key="idx" :label="idx + 1">{{ item }}</Radio>
                                </RadioGroup>
                            </div>

                            <div class="title">时间:</div>
                            <div class="timeslot-box">
                                <div class="item-box">
                                    <DatePicker v-model="handleData.start_date" type="date" placeholder="开始日期" style="width:120px" />
                                    <div style="margin:0 5px">:</div>
                                    <TimePicker type="time" v-model="handleData.start_time" format="HH:mm" placeholder="开始时间" style="width:100px" />
                                </div>
                                <div style="margin:0 10px">-</div>
                                <div class="item-box">
                                    <DatePicker v-model="handleData.end_date" type="date" placeholder="结束日期" style="width:120px" />
                                    <div style="margin:0 5px">:</div>
                                    <TimePicker type="time" v-model="handleData.end_time" format="HH:mm" placeholder="结束时间" style="width:100px" />
                                </div>
                            </div>

                            <block v-if="currentStepData.flowCode == 2002 && (!isEdit || isEditOptions)">
                                <div class="title">表决选项:</div>
                                <div class="option-box">
                                    <div class="item-box" v-for="(item, idx) in handleData.options" :key="item.id">
                                        <input class="input" type="text" v-model="item.value" :placeholder="'输入选项' + (idx + 1)" />
                                        <div v-if="idx > 0" class="remove-btn" @click="onRemoveOptionItem(idx)">
                                            <Icon type="md-close" />
                                        </div>
                                    </div>
                                    <div class="operation-box">
                                        <div class="multiple-btn">
                                            <checkbox :checked="handleData.multiple" @click="e => onChangeHandleData('multiple', !handleData.multiple)"></checkbox>
                                            <div class="name">多选</div>
                                        </div>
                                        <div class="add-btn" @click="onAddOptionItem">
                                            <Icon type="md-add" />
                                        </div>
                                    </div>
                                </div>
                                <div class="title">表决说明:</div>
                                <div class="textarea-box">
                                    <textarea v-model="handleData.content" placeholder="请输入表决说明内容" maxlength="300"></textarea>
                                </div>
                            </block>

                            <block v-else>
                                <div class="title">{{ currentStepData.title }}内容:</div>
                                <div class="textarea-box">
                                    <textarea v-model="handleData.content" :placeholder="'请输入' + currentStepData.title + '内容'" maxlength="800"></textarea>
                                </div>

                                <block v-if="currentStepData.flowCode == 2001">
                                    <div class="title">直播议事会（直播房间号）:</div>
                                    <div class="input-box">
                                        <Input v-model="handleData.live" placeholder="直播房间号（可选）" />
                                    </div>
                                </block>

                                <div class="title">上传图片（可选）：</div>
                                <ElemUpload ref="upload_photo" :value="handleData.photo" :length="9"></ElemUpload>

                                <div class="title">上传文件（可选）：</div>
                                <ElemUpload ref="upload_file" :value="handleData.files" type="file"></ElemUpload>
                            </block>

                            <button class="submit-btn" @click="onSubmitHandle">提交</button>
                        </div>
                    </div>
                </div>
                <div class="evolve-info" v-if="auxiliary ? auxiliary.flowCode === 2000 : detail">
                    <div class="title">{{ detail.title }}</div>
                    <div class="deta">公开时间：{{ $core.formatDate(new Date(detail.gmtCreate), "yyyy-MM-dd hh:mm:ss") }}</div>
                    <div class="content" v-html="detail.content || '-'"></div>
                    <div class="photo-box" v-if="detail.photos && detail.photos.length > 0">
                        <div class="item-box" v-for="(item, idx) in detail.photos" :key="'photo-' + idx" @click="onPredivImage(detail.photos, item)">
                            <img class="img" :src="item" mode="aspectFill" />
                        </div>
                    </div>
                    <div class="info-item border">
                        <p class="name">参与人员：</p>
                        <p class="value">{{ detail.participating || "无" }}</p>
                    </div>
                    <div class="info-item">
                        <p class="name">列席人员：</p>
                        <p class="value">{{ detail.attendance || "无" }}</p>
                    </div>
                    <div class="info-item">
                        <p class="name">主持人员：</p>
                        <p class="value">{{ detail.host || "无" }}</p>
                    </div>
                    <div class="info-item">
                        <p class="name">审核人员：</p>
                        <p class="value">{{ detail.examine || "无" }}</p>
                    </div>
                    <div class="info-item border">
                        <p class="name">操作人：</p>
                        <p class="value">{{ detail.createBy }}</p>
                    </div>
                    <div class="info-item">
                        <p class="name">操作时间：</p>
                        <p class="value">{{ $core.formatDate(new Date(detail.gmtCreate), "yyyy-MM-dd hh:mm:ss") }}</p>
                    </div>
                </div>
                <div class="evolve-info" v-else-if="auxiliary" :set="(flowJson = auxiliary.flowJson)">
                    <div class="title">{{ auxiliary.title }}</div>
                    <div class="hint" v-if="auxiliary.flowCode === 2003">公示类型：{{ ["主动公示", "免于公示"][auxiliary.flowJson.publicity == 1 ? 0 : 1] }}</div>
                    <div class="hint" v-if="auxiliary.flowCode === 2004">公开类型：{{ ["主动公开评议", "免于公开评议"][auxiliary.flowJson.publicity == 1 ? 0 : 1] }}</div>
                    <div class="deta">时间：{{ auxiliary.flowJson.startTime + " - " + auxiliary.flowJson.endTime }}</div>
                    <div class="content" v-html="auxiliary.flowJson.content || '-'"></div>
                    <div class="photo-box" v-if="auxiliary.flowJson.photo && auxiliary.flowJson.photo.length > 0">
                        <div
                            class="item-box"
                            v-for="(item, idx) in typeof auxiliary.flowJson.photo === 'string' ? JSON.parse(auxiliary.flowJson.photo) : auxiliary.flowJson.photo"
                            :key="'photo-' + idx"
                            @click="onPredivImage(typeof auxiliary.flowJson.photo === 'string' ? JSON.parse(auxiliary.flowJson.photo) : auxiliary.flowJson.photo, item)"
                        >
                            <img class="img" :src="item" mode="aspectFill" />
                        </div>
                    </div>
                    <div class="info-item border">
                        <p class="name">开始时间：</p>
                        <p class="value">{{ flowJson.startTime }}</p>
                    </div>
                    <div class="info-item">
                        <p class="name">结束时间：</p>
                        <p class="value">{{ flowJson.endTime }}</p>
                    </div>
                    <div class="info-item" v-if="auxiliary.flowJson.live">
                        <p class="name">直播房间号：</p>
                        <p class="value">{{ auxiliary.flowJson.live }}</p>
                    </div>
                    <div class="info-item border" v-if="auxiliary.flowJson.creatorName">
                        <p class="name">操作人：</p>
                        <p class="value">{{ auxiliary.flowJson.creatorName }}</p>
                    </div>
                    <div class="info-item" v-if="auxiliary.flowJson.gmtCreate">
                        <p class="name">操作时间：</p>
                        <p class="value">{{ $core.formatDate(new Date(auxiliary.flowJson.gmtCreate), "yyyy-MM-dd hh:mm:ss") }}</p>
                    </div>
                </div>
                <div class="auxiliary-box" v-if="auxiliary && auxiliary.flowCode !== 2003">
                    <p class="title">{{ getAuxiliaryName(auxiliary.flowCode) }}</p>
                    <div class="auxiliary-body">
                        <CompComment v-if="auxiliary.flowCode === 2000 || auxiliary.flowCode === 2004" forumType="CHAMBER" :forumId="detail.id"></CompComment>
                        <div class="chart-page" v-else-if="auxiliary.flowCode === 2001">
                            <div class="live-btn" v-if="auxiliary.flowJson.live" @click="$refs.comp_live.room(auxiliary.flowJson.live)">查看议事会议直播回放</div>
                            <iframe class="iframe" :src="getChatIframeSrc(auxiliary.flowJson)" frameborder="0" style="display: block;"></iframe>
                        </div>
                        <Vote v-else-if="auxiliary.flowCode === 2002" :issueId="id" :flowId="auxiliary.vo.id" :voteId="auxiliary.flowJson.voteId" :title="detail.title" :time="`${auxiliary.flowJson.startTime} - ${auxiliary.flowJson.endTime}`"></Vote>
                    </div>
                </div>
            </div>
        </CompContent>

        <CompModal ref="comp_modal" title="表决情况" width="600px">
            <!-- <Vote ref="vote"></Vote> -->
            <Chat ref="chat"></Chat>
        </CompModal>

        <CompLive ref="comp_live"></CompLive>

        <Publish ref="publish" @on-submit="getFlowInfo"></Publish>
        <Preview ref="preview"></Preview>
    </div>
</template>

<script>
import proxy from "../../api/proxy"

import CompModal from "../residentdatabase/components/CompModal.vue"
import CompContent from "../customform/components/CompContent.vue"
import TimelineEdit from "./components/TimelineEdit"
import ElemUpload from "../customform/components/ElemUpload"
import Vote from "./components/Vote.vue"
import Chat from "./components/Chat.vue"

import Request from "../jointly/utils/request"
import CompComment from "./components/CompComment.vue"
import Publish from "./components/Publish"
import Preview from "../comment/components/Preview.vue"
import CompLive from "./components/CompLive.vue"

export default {
    components: {
        TimelineEdit,
        CompModal,
        CompContent,
        ElemUpload,
        Vote,
        Chat,
        CompComment,
        Publish,
        Preview,
        CompLive,
    },
    data() {
        return {
            id: this.$core.getUrlParam("id"),
            current: null, //timeline初始定位
            timelineData: [
                {
                    title: "议题公开", // 名称
                    step: "进行中", // 步骤
                    status: null, // 0:已跳过 1:进行中 2:已完成 3:未开始
                    action: 0, // 当前执行事件标记
                    flowCode: 2000,
                    btns: this.getBtns(null, 2000),
                },
                {
                    title: "议事协商", // 名称
                    step: "未开启", // 步骤
                    status: null, // 0:已跳过 1:进行中 2:已完成 3:未开始
                    action: 1, // 当前执行事件标记
                    flowCode: 2001,
                    btns: this.getBtns(null, 2001),
                },
                {
                    title: "议事表决", // 名称
                    step: "未开启", // 步骤
                    status: null, // 0:已跳过 1:进行中 2:已完成 3:未开始
                    action: 2, // 当前执行事件标记
                    flowCode: 2002,
                    btns: this.getBtns(null, 2002),
                },
                {
                    title: "结果公示", // 名称
                    step: "未开启", //步骤
                    status: null, // 0:已跳过 1:进行中 2:已完成 3:未开始
                    action: 3, // 当前执行事件标记
                    flowCode: 2003,
                    btns: this.getBtns(null, 2003),
                },
                {
                    title: "执行监督", // 名称
                    step: "未开启", // 步骤
                    status: null, // 0:已跳过 1:进行中 2:已完成 3:未开始
                    action: 4, // 当前执行事件标记
                    flowCode: 2004,
                    btns: this.getBtns(null, 2004),
                },
            ],
            // 详情数据
            detail: null,
            // 展开详情内容
            isUnfoldDetail: false,
            // 当前选择的步骤数据
            currentStepData: null,
            // 是否显示处理表单页面
            isDisplayHandlePage: false,
            // 处理弹窗的表单数据
            handleData: {},
            // 是否为编辑模式
            isEdit: false,
            // 可否修改表决选项
            isEditOptions: false,
            // 是否完成全部流程
            isDone: false,
            // 文件 input id
            fileInputId: this.$core.randomString(),
            // 当前所选右侧辅助内容
            auxiliary: null,
        }
    },

    watch: {
        isDisplayHandlePage(v) {
            if (!v) {
                this.handleData = {}
                this.isEdit = false
            }
        },

        handleData: {
            handler(v) {
                if (v && !v.options) {
                    this.$set(v, "options", [
                        {
                            id: this.$core.randomString(),
                        },
                    ])
                }
            },
            immediate: true,
        },
    },

    created() {
        // 请求获取数据
        this.getFlowInfo()
    },

    methods: {
        getFlowInfo() {
            return Request.get("/gateway/syinfopublish/api/app/chamber/getIssueFlowInfo", {
                issueId: this.id,
            }).then(res => {
                var maxFlowCode = 2000 - 1
                // 是否完成所有步骤
                this.isDone = res.flowCode === "done"
                // 解析图片 JSON 字符串
                if (res.photo) {
                    if (/^\[.*\]$/.test(res.photo)) res.photos = JSON.parse(res.photo)
                    else res.photos = res.photo.split(",")
                }

                if (res.flowVoList) {
                    for (let i = 0, tld = this.timelineData; i < tld.length; i++) {
                        let v = tld[i]

                        for (let li = 0, l = res.flowVoList; li < l.length; li++) {
                            let lv = l[li]

                            if (v.flowCode == lv.flowCode) {
                                v.status = lv.flowStatus
                                v.step = this.getStepName(lv.flowStatus)
                                v.btns = this.getBtns(lv.flowStatus, lv.flowCode)
                                v.vo = lv
                                v.flowJson = lv.flowJson ? JSON.parse(lv.flowJson) : {}

                                if (Number(lv.flowCode) > maxFlowCode) {
                                    maxFlowCode = Number(lv.flowCode)
                                }
                            }
                        }
                    }
                }

                const names = { 100: "participating", 101: "attendance", 102: "host", 103: "examine" }
                const types = ["orgCodeList", "groupList", "userList"]

                // 获取参与人用户信息
                for (let i = 0, ul = res.chamberUserList; i < ul?.length; i++) {
                    let v = ul[i],
                        u = JSON.parse(v.userJson)

                    var pti

                    // 查找已选用户类型
                    for (let ti = 0; ti < types.length; ti++) {
                        if (u[types[ti]]) {
                            pti = ti
                        }
                    }

                    // 获取用户列表
                    let us = u[types[pti]]

                    var values

                    switch (pti) {
                        case 0:
                            values = "全体居民"
                            break
                        case 1:
                            values = us.map(u => u.groupName).join(",")
                            break
                        case 2:
                            values = us.map(u => u.realName).join(",")
                            break
                    }

                    res[names[v.userType]] = values
                }

                this.current = maxFlowCode - 2000 + 1
                this.detail = res
            })
        },

        getStepName(status) {
            return ["已跳过", "进行中", "已完成", "未开始", "已结束"][status] || "未知"
        },

        getBtnName(flowCode) {
            return { 2001: "开启讨论", 2002: "开始表决", 2003: "开始公示", 2004: "开启评议" }[flowCode] || "未知"
        },

        getEditBtnName(flowCode) {
            return { 2001: "修改讨论时间", 2002: "修改表决时间", 2003: "修改结果公示", 2004: "修改执行监督" }[flowCode] || "未知"
        },

        getAuxiliaryName(flowCode) {
            return { 2000: "反馈详情", 2001: "协商详情", 2002: "表决详情", 2003: "结果公示", 2004: "监督反馈" }[flowCode] || "未知"
        },

        getBtns(status, flowCode, name) {
            if (flowCode == 2000) {
                return this.isDone
                    ? []
                    : [
                          {
                              name: "修改议题内容",
                              onClick: () => {
                                  this.$refs.publish.display(this.id)
                              },
                          },
                      ]
            }

            if (status == 0) {
                return
            }

            if (status == null) {
                return [
                    {
                        name: "跳过",
                        onClick: () => {
                            this.$Modal.confirm({
                                title: "提示",
                                content: "确认跳过当前流程吗？",
                                onOk: () => {
                                    this.onNextFlow("0", flowCode)
                                },
                            })
                        },
                    },
                    {
                        name: this.getBtnName(flowCode),
                        onClick: v => {
                            this.currentStepData = v
                            this.isDisplayHandlePage = true
                        },
                    },
                ]
            } else if (status == 1 || status == 3) {
                return [
                    {
                        name: this.getEditBtnName(flowCode),
                        onClick: v => {
                            let hd = this.handleData

                            for (let i = 0, l = this.detail.flowVoList; i < l.length; i++) {
                                let v = l[i]

                                if (v.flowCode == flowCode) {
                                    let json = JSON.parse(v.flowJson),
                                        start = json.startTime.split(/[ ]/),
                                        end = json.endTime.split(/[ ]/)

                                    this.onChangeHandleData("start_date", start[0])
                                    this.onChangeHandleData("start_time", start[1])
                                    this.onChangeHandleData("end_date", end[0])
                                    this.onChangeHandleData("end_time", end[1])

                                    if (json.publicity) {
                                        this.onChangeHandleData("publicity_type", json.publicity == 1 ? 0 : 1)
                                    }

                                    if (json.content) {
                                        this.onChangeHandleData("content", json.content)
                                    }

                                    this.onChangeHandleData("live", json.live)

                                    // 处理图片
                                    if (json.photo) {
                                        let ps = []
                                        let photo = /^\[.*\]$/.test(json.photo) ? JSON.parse(json.photo) : json.photo.split(",")

                                        for (let pi = 0; pi < photo.length; pi++) {
                                            ps.push({
                                                src: photo[pi],
                                            })
                                        }

                                        this.onChangeHandleData("photo", ps)
                                    }

                                    // 处理文件
                                    if (json.files) {
                                        this.onChangeHandleData("files", JSON.parse(json.files))
                                    }

                                    hd.editId = v.id

                                    if (flowCode == 2002) {
                                        if (new Date(json.endTime).getTime() > new Date().getTime()) {
                                            this.isEditOptions = true
                                            this.getVoteDetail(v.id, v.issueId)
                                        }

                                        this.onChangeHandleData("multiple", json.voteType == "2")
                                    }

                                    break
                                }
                            }

                            this.isEdit = true
                            this.currentStepData = v
                            this.isDisplayHandlePage = true
                        },
                    },
                ]
            }
        },

        /**
         * 获取表决选项详情
         */
        getVoteDetail(flowId, issueId) {
            return Request.get("/gateway/syinfopublish/api/app/chamber/statVoteRecordRank", {
                issueId: issueId,
                flowId: flowId,
            }).then(res => {
                const options = []

                for (let i = 0; i < res.length; i++) {
                    options.push({
                        id: this.$core.randomString(),
                        value: res[i].voteItemName,
                    })
                }

                this.$set(this.handleData, "options", options)
            })
        },

        /**
         * 下拉刷新
         */
        scrollToUpper(params, callback) {
            this.getFlowInfo().finally(callback)
        },

        // --- 弹窗表单处理事件 ---

        onSelectFile() {
            var dom = document.getElementById(this.fileInputId)

            if (!dom) {
                dom = document.createElement("input")

                dom.hidden = "true"
                dom.style.display = "none"
                dom.type = "file"
                // 支持 word excel pdf
                dom.accept = "application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.pdf"

                document.body.appendChild(dom)
            }

            dom.onchange = evt => {
                let files = this.handleData.files || []
                var ls = evt.target.files

                for (let i = 0; i < ls?.length; i++) {
                    let file = ls[i]

                    files.push({
                        id: this.$core.randomString(),
                        file: file,
                        name: file.name,
                    })
                }

                this.onChangeHandleData("files", files)

                // 清空 input 文件
                dom.value = ""
            }

            dom.click()
        },

        onRemoveFileItem(idx) {
            let files = this.handleData.files || []
            // 移除
            files.splice(idx, 1)
            // 更新
            this.onChangeHandleData("files", files)
        },

        onChangeHandleData(name, value) {
            this.$set(this.handleData, name, value)
        },

        onAddOptionItem() {
            const op = this.handleData?.options || []

            op.push({
                id: this.$core.randomString(),
            })

            this.$set(this.handleData, "options", op)
        },

        onRemoveOptionItem(idx) {
            this.handleData?.options?.splice(idx, 1)
        },

        /**
         * 提交处理表单
         */
        async onSubmitHandle() {
            const fd = this.handleData
            const csd = this.currentStepData

            var startTime = `${this.$core.formatDate(fd.start_date ? new Date(fd.start_date) : new Date(), "yyyy-MM-dd")} ${fd.start_time || "00:00"}`
            var endTime = `${this.$core.formatDate(fd.end_date ? new Date(fd.end_date) : new Date(), "yyyy-MM-dd")} ${fd.end_time || "00:00"}`
            var startDate = new Date(startTime)
            var endDate = new Date(endTime)

            if (endDate.getTime() < new Date().getTime()) {
                return this.$Message.warning("结束时间不能小于当前时间")
            }

            if (endDate.getTime() < startDate.getTime()) {
                return this.$Message.warning("结束时间不能小于开始时间")
            }

            var vo = {
                orgCode: parent.vue.loginInfo.userinfo.communityCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                startTime: startTime,
                endTime: endTime,
                content: fd.content,
            }

            switch (csd.flowCode) {
                case 2001:
                    vo.live = fd.live
                    break
                case 2002:
                    if ((this.isEdit && this.isEditOptions) || !this.isEdit) {
                        const vi = []

                        for (let i = 0, os = fd?.options; i < os?.length; i++) {
                            if (os[i].value) {
                                vi.push({
                                    itemNum: i + 1,
                                    title: os[i].value,
                                    seq: i,
                                })
                            }
                        }

                        if (vi.length < 2) {
                            return this.$Message.warning("表决选项至少需要2个或以上")
                        }

                        vo.voteItem = vi
                        vo.voteType = fd.multiple ? 2 : 1
                    }

                    break
                case 2003:
                case 2004:
                    // 是否公开
                    vo.publicity = fd.publicity_type == 0 ? 1 : 2
                default:
                    break
            }

            const photos = await this.$refs.upload_photo?.getFiles()
            // 判断是否存在图片文件
            if (photos?.length > 0) {
                vo.photo = JSON.stringify(photos)
            }

            const files = await this.$refs.upload_file?.getFiles()
            // 判断是否存在文件
            if (files?.length > 0) {
                vo.files = JSON.stringify(files)
            }

            Request.post(
                `/gateway/syinfopublish/api/app/chamber/${this.isEdit ? "updateIssueFlow" : "openOrSkipFlow"}`,
                {
                    flowCode: csd.flowCode,
                    flowJsonVo: vo,
                    flowStatus: "1",
                    issueId: this.id,
                    id: fd.editId,
                },
                {
                    json: true,
                }
            ).then(() => {
                this.$Message.success(this.isEdit ? "修改成功" : "提交成功")
                // 关闭弹窗
                this.isDisplayHandlePage = false
                // 刷新数据
                this.getFlowInfo()
            })
        },

        /**
         * 下一个流程
         */
        onNextFlow(flowStatus, flowCode) {
            Request.post(
                "/gateway/syinfopublish/api/app/chamber/openOrSkipFlow",
                {
                    flowCode: flowCode,
                    flowStatus: flowStatus,
                    issueId: this.id,
                },
                {
                    json: true,
                }
            ).then(res => {
                this.$Message.success(flowCode === "done" ? "提交成功" : "跳过成功")
                // 刷新数据
                this.getFlowInfo()
            })
        },

        /**
         * 完成并归档
         */
        onSubmitComplete() {
            this.onNextFlow("2", "done")
        },

        onSelectFlow(evt) {
            console.log(evt)
            this.auxiliary = evt.value.item
        },

        onPredivImage(urls, url) {
            this.$refs.preview.display(urls, url)
        },

        getChatIframeSrc(res) {
            return `${proxy["/custom"].target}/#/pages/im/group/chat?businessType=1&businessCode=2&groupCode=${res.groupId}&token=${window.sessionStorage.getItem("accessToken")}&isShow=false`
        },
    },
}
</script>

<style lang="less">
.agendaroom-evolve {
    display: flex;
    min-height: 100%;
    overflow: hidden;

    .evolve-box {
        position: relative;
        width: 450px;
        padding-right: 10px;
        flex-shrink: 0;

        .scroll-box {
            width: 100%;
            height: 100%;
            overflow-y: auto;
        }
    }

    .evolve-info {
        position: relative;
        min-width: 400px;
        min-height: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-left: 1px solid #f3f3f3;
        flex-grow: 1;

        &:last-child {
            max-width: 600px;
        }

        .title {
            font-size: 16px;
            color: #333;
            text-align: center;
        }

        .hint {
            font-size: 13px;
            color: #666;
            text-align: center;
            margin-top: 10px;
            line-height: 1;
        }

        .deta {
            margin-top: 5px;
            width: 100%;
            text-align: center;
            font-size: 12px;
            color: #888;
        }

        .content {
            width: 100%;
            margin: 10px 0;
            font-size: 14px;
            color: #666;
            max-width: 500px;
            min-height: 150px;
        }

        .photo-box {
            width: 100%;
            margin: 10px 0;
            display: flex;
            flex-wrap: wrap;
            max-width: 500px;

            .item-box {
                cursor: pointer;
                position: relative;
                width: calc(100% / 3 - 12px / 3);
                padding-bottom: 28%;
                height: 0;
                margin: 3px 6px 3px 0;

                &:nth-child(3n) {
                    margin-right: 0;
                }

                .img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 4px;
                    overflow: hidden;
                }
            }
        }

        .detail-btn {
            margin-top: 10px;
            padding: 4px 10px;
            background: #2db7f5;
            color: #fff;
            font-size: 12px;
            line-height: 20px;
            border-radius: 5px;
            display: flex;
            align-items: center;

            .icon {
                margin-left: 5px;
                line-height: 22.5px;
            }
        }

        .info-item {
            padding-top: 15px;
            display: flex;
            max-width: 500px;
            width: 100%;
            line-height: 20px;

            &.border {
                margin-top: 10px;
                border-top: 1px solid #f3f3f3;
            }

            .name {
                color: #666;
                font-size: 13px;
                flex-shrink: 0;
                white-space: nowrap;
            }

            .value {
                color: #333;
                font-size: 14px;
                margin-left: 10px;
            }
        }
    }

    .agenda-scroll-content {
        .detail-menu {
            position: absolute;
            width: 185px;
            height: calc(100% - 40px);
            padding: 20px 5px;
            left: 0;
            top: 0px;
            overflow-y: auto;
            background-color: #f5f5f5;
            color: #555 !important;
        }

        .detail-content {
            position: relative;
            left: 185px;
            right: 0;
            top: 0;
            overflow: hidden;
            padding: 20px;
        }
    }

    .prompt {
        margin: 25px 15px;
        padding-bottom: 65px;
        font-size: 13px;
        color: #666;
    }

    .success-btn {
        position: absolute;
        bottom: 30px;
        left: calc(50% - 100px);
        width: 200px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: initial;
        background: #2db7f5;
        color: #fff;
        border-radius: 8px;
        font-size: 15px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        border: initial;
        cursor: pointer;

        &::after {
            border: initial;
        }
    }

    .handle-page {
        background: rgba(0, 0, 0, 0.6);
        overflow: hidden;
        z-index: 50;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        .handle-box {
            max-width: 700px;
            margin: 10px;
            background: #fff;
            padding: 0 20px 20px;
            transition: all 0.3s;
            border-radius: 10px;
            max-height: 80%;
            overflow-y: auto;

            .head-box {
                position: sticky;
                top: 0;
                width: 100%;
                height: 45px;
                border-bottom: 1px solid #e3e3e3;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: #fff;
                z-index: 10;

                > .title-box {
                    font-weight: bold;
                    display: flex;
                    align-items: center;

                    .icon-box {
                        margin-right: 10px;
                        font-size: 15px;
                        color: #14b16e;
                    }

                    .name {
                        color: #14b16e;
                    }
                }

                .cancel {
                    cursor: pointer;
                    color: #c3c3c3;
                }
            }

            .timeslot-box {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .item-box {
                    display: flex;
                    align-items: center;

                    .date {
                        width: 100%;
                        padding: 6px 8px;
                        border: 1px solid #e3e3e3;
                        border-radius: 5px;
                        box-sizing: border-box;
                        font-size: 12px;
                        line-height: 1;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .time {
                        position: relative;
                        padding: 6px 8px;
                        font-size: 12px;
                        line-height: 1;
                        flex-shrink: 0;
                        border: 1px solid #e3e3e3;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }

            .radio-box {
                margin-top: 20px;
                font-size: 13px;

                .radio-group {
                    display: flex;
                    justify-content: space-evenly;
                }
            }

            .option-box {
                max-height: 250px;
                overflow-y: auto;

                .item-box {
                    padding: 10px;
                    border: 1px solid #e3e3e3;
                    border-radius: 8px;
                    margin-top: 7px;
                    display: flex;

                    .input {
                        flex-grow: 1;
                        border: initial;
                    }

                    .remove-btn {
                        cursor: pointer;
                        margin-left: 5px;
                        padding: 0 5px 0 15px;
                        border-left: 1px solid #e3e3e3;
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 1;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-shrink: 0;
                    }
                }

                .operation-box {
                    position: sticky;
                    bottom: 0;
                    padding: 10px 0;
                    display: flex;
                    align-items: center;
                    background: #fff;

                    .multiple-btn {
                        display: flex;
                        align-items: center;

                        .weui-cells_checkbox {
                            display: flex;

                            .taro-checkbox_checked {
                                top: 0;
                                width: 20px;
                                height: 20px;
                                font-size: 20px;
                            }
                        }

                        .name {
                            margin-left: 7.5px;
                            font-size: 13px;
                            line-height: 1;
                        }
                    }

                    .add-btn {
                        position: absolute;
                        left: calc(50% - 40px);
                        width: 80px;
                        height: 28px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #2db7f5;
                        color: #fff;
                        font-size: 20px;
                        border-radius: 6px;
                        cursor: pointer;
                    }
                }
            }

            .textarea-box {
                textarea {
                    padding: 10px;
                    font-size: 14px;
                    min-height: 150px;
                    border: 0;
                    border: initial;
                    width: 100%;
                    border: 1px solid #e3e3e3;
                    border-radius: 8px;
                }
            }

            .files-box {
                border: 1px solid #e3e3e3;
                border-radius: 8px;

                > .item-box {
                    height: 40px;
                    margin: 10px;
                    padding: 0 10px;
                    display: flex;
                    align-items: center;
                    box-sizing: border-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    background: #2db7f5;
                    color: #fff;
                    border-radius: 6px;
                    font-size: 13px;

                    .icon {
                        flex-shrink: 0;
                    }

                    .name {
                        margin: 0 5px;
                        flex-grow: 1;
                    }
                }

                > .add-box {
                    height: 40px;
                    margin: 10px;
                    background: #fff;
                    border: 1px solid #f3f3f3;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 6px;
                }
            }

            .title {
                margin-top: 5px;
                font-size: 13px;
                color: #888;
                line-height: 35px;
            }

            .submit-btn {
                position: sticky;
                bottom: 0;
                width: 100%;
                height: 40px;
                background: #14b16e;
                color: #fff;
                border-radius: 7px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                margin-top: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                border: initial;
                cursor: pointer;

                &::after {
                    border: initial;
                }
            }

            &.show-box {
                opacity: 1;
            }
        }
    }

    .auxiliary-box {
        border-left: 1px solid #f3f3f3;
        width: 600px;
        flex-shrink: 0;

        > .title {
            padding: 20px 0 15px 0;
            margin: 0 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            font-size: 16px;
            color: #333;
            border-bottom: 1px solid #f3f3f3;
            box-sizing: content-box;
        }

        .auxiliary-body {
            width: 100%;
            height: calc(100% - 55px);
            overflow: auto;

            .chart-page {
                width: calc(100% - 40px);
                height: calc(100% - 40px);
                margin: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;

                .live-btn {
                    width: 100%;
                    max-width: 250px;
                    height: 40px;
                    margin-bottom: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #3ab5ec;
                    color: #fff;
                    border-radius: 6px;
                    font-size: 14px;
                    cursor: pointer;
                    flex-shrink: 0;
                }

                .iframe {
                    width: 100%;
                    overflow: hidden;
                    border: 1px solid #e3e3e3;
                    border-radius: 4px;
                    flex-grow: 1;
                }
            }
        }
    }
}
</style>
