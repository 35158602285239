<template>
    <div class="liefeng-agendaroom-timeline">
        <div class="at-timeline at-timeline--pending">
            <div
                class="at-timeline-item"
                v-for="(item, index) in data"
                :key="'timeline' + index"
                :class="{ available: isAvailable(item, index), ac: ac === index }"
                :style="'color:' + (item.status == 3 ? '#888888' : item.status == 1 ? '#19be6b' : item.status == 2 ? '#2d8cf0' : '#888888')"
                @click="onClickItem(item, index)"
            >
                <div class="at-timeline-item__tail" :style="'border: 1px solid ' + (item.status == 3 ? '#888888' : item.status == 1 ? '#19be6b' : item.status == 2 ? '#2d8cf0' : '#888888') + ';'"></div>
                <div class="at-timeline-item__icon">
                    <Icon v-if="item.status === '0'" type="ios-close-circle" />
                    <Icon v-else-if="item.status == 1" type="md-arrow-dropright-circle" />
                    <Icon v-else-if="item.status == 2" type="md-checkmark-circle" />
                    <Icon v-else-if="item.status == 3" type="md-remove-circle" />
                    <Icon v-else type="ios-time" />
                </div>
                <div class="at-timeline-item__content">
                    <div class="at-timeline-item__content-item">
                        <p>{{ item.title }}</p>
                        <div class="progress-btn" v-if="item.status != null && item.status != 0 && item.progress" @click.stop="item.progress.onClick(item)">{{ item.progress.name }}</div>
                    </div>
                    <div class="at-timeline-item__content-item at-timeline-item__content--sub">{{ item.step }}</div>
                </div>
                <div class="operate-box" v-if="current >= index">
                    <button class="btn-item" v-for="(btn, idx) in item.btns" :key="'btn' + idx" @click.stop="btn.onClick(item)">{{ btn.name }}</button>
                </div>
                <div class="activity-box">
                    <Icon type="ios-arrow-forward" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            ac: null,
        }
    },
    props: {
        current: { type: Number, required: false },
        data: {
            type: Array,
            default: () => {
                return []
            },
        },
    },

    watch: {
        data: {
            handler(v) {
                this.init()
            },
            immediate: true,
        },

        current: {
            handler(v) {
                this.init()
            },
            immediate: true,
        },
    },

    methods: {
        init() {
            const data = this.data,
                current = this.current

            if (!data || (!current && current !== 0) || this.isInit) return
            this.isInit = true

            var ac = current <= data.length ? current - 1 : data.length - 1

            if (!this.isAvailable(data[ac], ac)) {
                return
            }

            this.ac = ac

            this.$emit("on-select", {
                tag: "TimelineEdit",
                value: {
                    index: this.ac,
                    item: data[this.ac],
                },
            })
        },

        onClickItem(item, idx) {
            if (!this.isAvailable(item, idx)) return

            this.ac = idx

            this.$emit("on-select", {
                tag: "TimelineEdit",
                value: {
                    index: idx,
                    item: item,
                },
            })
        },

        isAvailable(item, index) {
            return index < this.current && item.status !== "0" && item.click !== false
        },
    },
}
</script>
<style lang="less">
.liefeng-agendaroom-timeline {
    transition: 0.2s all;
    padding: 15px 5px 15px 15px;

    .at-timeline-item {
        position: relative;
        padding: 15px 5px 15px 10px;
        margin: 10px 0;
        display: flex;
        border-radius: 6px;
        transition: all 0.2s;
        border: 1px solid transparent;

        &:last-child {
            .at-timeline-item__tail {
                display: none;
            }
        }

        &.available {
            cursor: pointer;

            .activity-box {
                opacity: 1;
                color: #cfcfcf;
            }

            &:hover {
                border-color: #e3e3e3;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

                .activity-box {
                    color: #666;
                }
            }
        }

        &.ac {
            border-color: #e3e3e3;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

            .activity-box {
                color: #666 !important;
            }
        }
    }
    .at-timeline-item__tail {
        position: absolute;
        top: 45px;
        bottom: -45px;
        left: 20px;
        border: 1px solid #aaa;
        z-index: 10;
    }
    .at-timeline-item__content--sub {
        color: inherit;
        font-size: 13px;
    }
    .at-timeline-item__icon,
    .at-timeline-item__dot .at-icon,
    .at-timeline-item__icon .at-icon {
        font-size: 23px;
        color: inherit;
        background: transparent;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 12;
    }
    .at-timeline-item__content {
        margin-left: 27.5px;
        color: inherit;
        font-size: 15px;
        line-height: 1.5;
        text-align: left;

        .at-timeline-item__content-item {
            display: flex;
            align-items: center;

            > p {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .progress-btn {
                cursor: pointer;
                margin-left: 10px;
                border: 1px solid #e3e3e3;
                padding: 7.5px 15px;
                line-height: 1;
                color: #3ed4bd;
                font-size: 13px;
                border-radius: 6px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .operate-box {
        margin-left: 10px;
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        .btn-item {
            cursor: pointer;
            padding: 0 15px;
            height: 30px;
            font-size: 13px;
            display: flex;
            align-items: center;
            margin: 0 0 0 5px;
            width: auto;
            border: 1px solid #e3e3e3;
            border-radius: 6px;
            background: #fdfdfd;
            color: #666;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &::after {
                border: initial;
            }
        }
    }

    .activity-box {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        margin-left: 20px;
        color: #666;
        opacity: 0;
        transition: all 0.2s;
        font-size: 18px;
    }
}
</style>
